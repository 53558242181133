/* eslint-disable no-lone-blocks */

export function ruleDecisionColor(activities, ruleId, activityCompleted) {
  let color = '#F9F9F9';
  activities?.forEach((activity) => {
    if (
      activity.status === 'completed' &&
      activity.type === 'action' &&
      activity.metadata?.action === 'ValidateRulesAction' &&
      activity.output
    ) {
      activity.output.forEach((output) => {
        if (output.ruleId === ruleId && activityCompleted) {
          if (output.status === 'APPROVED' || output.status === 'WARNING') {
            color = '#D1FFBD';
          } else if (output.status === 'REPROVED') {
            color = '#f9e4e4';
          }
        }
        if (output.ruleId === ruleId && output.status === 'PENDING') {
          color = '#e0ceff';
        }
      });
    }
  });

  return color;
}
