/* eslint-disable spaced-comment */
/* eslint-disable no-shadow */
/* eslint-disable react/self-closing-comp */
/* eslint-disable import/no-cycle */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import { Col, Row, Tooltip } from 'antd';
import React, { useMemo } from 'react';
import { useDrop } from 'react-dnd';
import { Handle, Position } from 'reactflow';
import useWorkflowSteps from '../../hooks/useWorkflowSteps';
import Rule from '../../Rules/InStep';
import './styles.less';

export const nodeSize = {
  width: 700,
  height: 650,
};

const RulesNode = (node) => {
  const { data, id } = node;

  const { accept, droppedItems, label, stepFunction } = data;

  const { handleChangeRuleStatus, removeNode, handleAddRule, handleRemoveRule, tree } =
    useWorkflowSteps();

  const [, drop] = useDrop({
    accept,
    drop: (item) => handleAddRule(id, item),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const hasChildrenStep = useMemo(() => {
    if (!tree) return false;

    let operationNode;
    tree.each((node) => {
      if (node.data.name === id) operationNode = node;
    });

    if (!operationNode) return false;

    if (!operationNode.children || operationNode.children?.length === 0) return false;

    const switchNode = operationNode.children[0];

    return switchNode.children.find((node) => node.data.type !== 'button');
  }, [tree, id]);

  const grouppedDroppedItems = useMemo(() => {
    const arr = [];
    const it = droppedItems;
    it.forEach((dropped) => {
      // primeira situação: se existir o elemento devo acrescentar o resultado
      if (arr?.find((a) => a.service === dropped?.service)) {
        // encontro o index do elemento para substituí-lo posteriormente
        const idxElementoEncontrado = arr.findIndex(
          (e2) => e2.service === dropped?.service
        );
        // substituo o elemento acrescentando o resultado
        arr[idxElementoEncontrado] = {
          service: arr[idxElementoEncontrado]?.service,
          typeSource: arr[idxElementoEncontrado]?.typeSource,
          serviceLabel: arr[idxElementoEncontrado]?.serviceLabel,
          rules: arr[idxElementoEncontrado]?.rules?.concat({
            label: dropped?.label,
            name: dropped?.name,
            ruleId: dropped?.ruleId,
            ruleType: dropped?.ruleType,
            statusConfig: dropped?.statusConfig,
            defaultStatus: dropped?.defaultStatus,
          }),
        };
        // segunda situação: se não existir o elemento
        // devo criar um array novo com primeiro resultado
      } else {
        arr.push({
          service: dropped?.service,
          typeSource: dropped?.typeSource,
          serviceLabel: dropped?.serviceLabel,
          rules: [
            {
              label: dropped?.label,
              name: dropped?.name,
              ruleId: dropped?.ruleId,
              ruleType: dropped?.ruleType,
              statusConfig: dropped?.statusConfig,
              defaultStatus: dropped?.defaultStatus,
            },
          ],
        });
      }
    });
    return arr;
  }, [droppedItems]);

  return (
    <>
      {stepFunction !== 'initial' && (
        <Handle
          type="target"
          position={Position.Top}
          style={{ width: 0, height: 0, opacity: 0, top: 0 }}
          isConnectable={false}
        />
      )}
      <div
        ref={drop}
        style={{
          color: 'white',
          fontSize: '10px',
          borderRadius: '4px',
          backgroundColor: data?.ActivityCompleted ? '#EBF1FE' : '#F9F9F9',
          width: 500,
          minHeight: 550,
          border: '2px solid #e8e8e8',
        }}
        data-testid="dustbin"
        className="flex fd-column"
      >
        <div
          className={
            data?.ActivityCompleted ? 'container-title' : 'container-title-not-completed '
          }
        >
          {label}
          {!hasChildrenStep && stepFunction !== 'initial' && (
            <div
              onClick={() => removeNode(id)}
              className="flex center"
              style={{ cursor: 'pointer' }}
            ></div>
          )}
        </div>
        <div className="flex center fd-column">
          <div className="mrg-horizon-20 mrg-top-10">
            {grouppedDroppedItems?.map((service) => (
              <div className="service-box">
                <span className="subtitle-rule">
                  <Tooltip title="Service">
                    <b className="text-dark mrg-right-10">{service?.serviceLabel}</b>
                  </Tooltip>
                </span>

                {service?.rules?.map((item) => (
                  <Row className="no-mrg no-pdd">
                    <Col span={24} className="no-mrg no-pdd">
                      <Rule
                        name={item?.name}
                        type="rules"
                        removeBox={() => handleRemoveRule(id, item)}
                        style={{ width: '90%' }}
                        service={service?.service}
                        typeSource={service?.typeSource}
                        label={item?.label}
                        ruleId={item?.ruleId}
                        ruleType={item?.type}
                        serviceLabel={service?.title}
                        key={item?.name}
                        statusConfig={item?.statusConfig}
                        defaultStatus={item?.defaultStatus}
                        activityCompleted={data?.ActivityCompleted}
                        activities={data?.activities}
                        handleChangeRuleStatus={(items) =>
                          handleChangeRuleStatus(id, items)
                        }
                      />
                    </Col>
                  </Row>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
      <Handle
        type="source"
        position={Position.Bottom}
        id="a"
        style={{ width: 0, height: 0, opacity: 0, bottom: 0 }}
        isConnectable={false}
      />
    </>
  );
};

export default RulesNode;
